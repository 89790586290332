.poem {
    font-family: "monospace";
    color: rgb(225, 225, 225);
    text-align: center;
    margin: auto;
    width: fit-content;
    min-height: 400px;
    margin-bottom: 5rem;
    background-color: #060607;
    .text-wrapper {
        pre {
            font-size: 14px;
            text-align: left;
            margin-top: 0;
            margin-bottom: 0.5rem;
            margin-left: .75rem;
        }
        h1 {
            font-size: 20px;
            text-align: left;
            margin-bottom: 2rem;
        }
    }

    div.shareWrapper {
        display: "flex";
        width: 100%;
        flex-direction: column;
        text-align: left;
        button.shareButton {

            margin-top: 2rem;
             font-size: 14px;
             background-color: #060607;
             border: none;
             color: white;
             &:hover {
                 border-color: greenyellow;
                 color: greenyellow;
             }
         }
    }
  
}





