.search-box{
  width: fit-content;
  height: fit-content;
  font-family: monospace;
  position: relative;
}
.input-search{
  height: 25px;
  font-family: monospace;
  width: 62%;
  border-style: solid;
  padding: 10px;
  font-size: 18px;
  letter-spacing: 2px;
  outline: none;
  border-radius: 0px;
  border-right: none;
  border-left: none;
  border-bottom: none;
  transition: all .5s ease-in-out;
  background-color: transparent;
  padding-right: 40px;
  color:#fff;
 
}
.input-search::placeholder{
  color:rgba(255,255,255,.5);
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: 100;
  font-family: monospace;
}

.input-search:focus{
  width: 65%;
  height: 50px;
  text-align: left;
  font-family: monospace;
  border-radius: 0px;
  border-style: none;
  background-color: transparent;
  border-bottom:1px solid rgba(255,255,255,.5);
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}