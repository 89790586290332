.poem {
  font-family: "monospace";
  color: rgb(225, 225, 225);
  text-align: center;
  margin: auto;
  width: -moz-fit-content;
  width: fit-content;
  min-height: 400px;
  margin-bottom: 5rem;
  background-color: #060607;
}
.poem .text-wrapper pre {
  font-size: 14px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0.5rem;
  margin-left: 0.75rem;
}
.poem .text-wrapper h1 {
  font-size: 20px;
  text-align: left;
  margin-bottom: 2rem;
}
.poem div.shareWrapper {
  display: "flex";
  width: 100%;
  flex-direction: column;
  text-align: left;
}
.poem div.shareWrapper button.shareButton {
  margin-top: 2rem;
  font-size: 14px;
  background-color: #060607;
  border: none;
  color: white;
}
.poem div.shareWrapper button.shareButton:hover {
  border-color: greenyellow;
  color: greenyellow;
}

.PoemWheel {
  max-height: 200px;
  max-width: 500PX;
  bottom: 0;
  overflow-y: auto;
  margin: auto;
  padding: 0.5rem;
  border-style: solid;
  border-color: white;
  border-left: none;
  border-right: none;
  scrollbar-color: white transparent;
  scrollbar-width: none;
  scroll-snap-align: center;
}
.PoemWheel button {
  margin: auto;
  margin-top: 2rem;
  width: 75%;
  font-size: 14px;
  background-color: #060607;
  border-radius: 0.25rem;
  border-color: white;
  border-left: none;
  border-right: none;
  border-top: none;
  color: white;
}
.PoemWheel button.selected {
  border-color: greenyellow;
  color: greenyellow;
}

.search-box {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  font-family: monospace;
  position: relative;
}

.input-search {
  height: 25px;
  font-family: monospace;
  width: 62%;
  border-style: solid;
  padding: 10px;
  font-size: 18px;
  letter-spacing: 2px;
  outline: none;
  border-radius: 0px;
  border-right: none;
  border-left: none;
  border-bottom: none;
  transition: all 0.5s ease-in-out;
  background-color: transparent;
  padding-right: 40px;
  color: #fff;
}

.input-search::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: 100;
  font-family: monospace;
}

.input-search::placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: 100;
  font-family: monospace;
}

.input-search:focus {
  width: 65%;
  height: 50px;
  text-align: left;
  font-family: monospace;
  border-radius: 0px;
  border-style: none;
  background-color: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}

html {
  background-color: #060607;
  font-family: "Calibri", sans-serif;
  max-width: 800px;
  margin: auto;
}

.row {
  display: flex;
  flex-direction: row;
}

.col {
  width: 50%;
  display: flex;
  flex-direction: column;
}

a {
  font-family: "Calibri", sans-serif;
  text-align: center;
  color: greenyellow;
  margin: auto;
  margin-bottom: 3rem;
  margin-top: 3rem;
  font-size: 24px;
}

canvas {
  width: 100%;
  height: 100%;
  color: transparent;
}/*# sourceMappingURL=index.css.map */