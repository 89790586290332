@import "./poems";
@import "./poemWheel";
@import "./searchBar";

html {
    background-color: #060607;
    font-family: "Calibri", sans-serif;
    max-width: 800px;
    margin: auto;
}



.row {
    display: flex;
    flex-direction: row;
}

.col {
    width: 50%;
    display: flex;
    flex-direction: column;
}

a {
    font-family: "Calibri", sans-serif;
    text-align: center;
    color: greenyellow;
    margin: auto;
    margin-bottom:3rem;
    margin-top:3rem;
    font-size: 24px;
}

canvas {
    width: 100%;
    height: 100%;
    color: transparent;
}
