.PoemWheel {
    max-height: 200px;
    max-width: 500PX;
    bottom: 0;
    overflow-y: auto;
    margin: auto;
    padding: .5rem;
    border-style: solid;
    border-color: white;
    border-left: none;
    border-right: none;
    scrollbar-color: white transparent;
    scrollbar-width: none;
    scroll-snap-align: center;
    button {
        margin: auto;
        margin-top: 2rem;
        width: 75%;
        font-size: 14px;
        background-color: #060607;
        border-radius: .25rem;
        border-color: white;
        border-left:none;
        border-right:none;
        border-top:none;
        color: white;
        &.selected {
            border-color: greenyellow;
            color: greenyellow;
        }
    }
}